import React,{Suspense,lazy,useEffect}from "react";import ReactDOM from "react-dom/client";import{ThemeProvider}from "@mui/material";import{theme}from "./Theme";import{BrowserRouter}from "react-router-dom";import CssBaseline from "@mui/material/CssBaseline";import{store}from "./Redux/store/store";import{Provider}from "react-redux";import{init as initApm}from "@elastic/apm-rum";import GlobalToastContainer from "./component/GlobalToastContainer";import{Box}from "@mui/material";import{LoaderGif}from "./component/ConstantImagesURL";import "react-toastify/dist/ReactToastify.css";import "./index.css";import{HelmetProvider}from 'react-helmet-async';const App=lazy(()=>import("./App"));const initializeApm=()=>{try{const apm=initApm({serviceName:process.env.REACT_APP_APM_SERVICE_NAME,environment:process.env.REACT_APP_APM_ENVIRONMENT,serverUrl:process.env.REACT_APP_APM_SERVER_URL,serviceVersion:"1.0.0",logLevel:"info",breakdownMetrics:!0,transactionSampleRate:1.0,});apm.startTransaction("App Load","app-load")}catch(error){console.error("Failed to initialize APM:",error)}};if(process.env.REACT_APP_WEB_URL!=="http://localhost:3000"){try{window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)}
gtag('js',new Date());gtag('config',process.env.REACT_APP_GA_TRACKING_ID,{cookie_flags:'SameSite=Strict;Secure',transport_url:'https://www.google-analytics.com'})}catch(error){console.error('Google Analytics initialization error:',error)}
initializeApm()}
const RootComponent=()=>{const detailedUrlCheck=/\/(annonce-detail|estimation-result)\//.test(window.location.pathname);useEffect(()=>{if(detailedUrlCheck){localStorage.setItem("detailedUrlCheck",window.location.pathname)}},[detailedUrlCheck]);return(<HelmetProvider><Provider store={store}><ThemeProvider theme={theme}><BrowserRouter><CssBaseline/><GlobalToastContainer/><Suspense
fallback={<Box
display="flex"
justifyContent="center"
alignItems="center"
height="100vh"><img
src={LoaderGif}
alt="Loading"
style={{width:"80px",height:"80px"}}
loading="lazy"/></Box>}><App/></Suspense></BrowserRouter></ThemeProvider></Provider></HelmetProvider>)};const root=ReactDOM.createRoot(document.getElementById("root"));root.render(<RootComponent/>)